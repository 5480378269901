var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.toastGenerator, function (toast) {
      return _c("mew-toast", {
        key: _vm.getName(toast) + _vm.getRef(toast),
        ref: _vm.getName(toast),
        refInFor: true,
        attrs: {
          persistent: false,
          duration: _vm.duration,
          "toast-type": _vm.getRef(toast),
          text: _vm.text,
          "link-obj": _vm.linkObj,
        },
        on: { closed: _vm.onClose },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }